import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toastSuccessMessage, toastSuccessMessageError } from "../../../common/tostShow/TostShow";
import { educationAdd, educationDelete, educationIDGet, educationUpdate, getEducation } from "../../../../api/Api";
import { Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { message, Popconfirm } from "antd";

function EducationInfo() {
  const [show, setShow] = useState(false);


  const [smShow, setSmShow] = useState(false);

  const [loadingSpin, setLoginSpin] = useState(false)


  const [initialValue, setInitialValue] = useState({
    degree: '',
    institution: '',
    start: '',
    end: '',
    status: ''
  })

  const [error, setError] = useState({})
  const [selectedId, setSelectedId] = useState(null);

  // console.log(error);

  const degreeRef = useRef(null);
  const institutionRef = useRef(null);
  const startRef = useRef(null);
  const endRef = useRef(null);
  const statusRef = useRef(null);


  const handleClose = () => setShow(false);
  const handleShow = () => {
    setSelectedId(null); // Reset selected ID when adding new data
    setInitialValue({ degree: '', institution: '', start: '', end: '', status: '' });
    setShow(true);
    setShow(true)
  };


  const validation = (formData) => {
    const errorDta = {}
    if (!formData?.degree) {
      errorDta.degree = 'Degree is Required!'
    }

    if (!formData?.institution) {
      errorDta.institution = 'Institution is Required !'
    }
    if (!formData?.start) {
      errorDta.start = 'Start is Required !'
    }
    if (!formData?.end) {
      errorDta.end = 'End is Required !'
    }
    if (!formData?.status) {
      errorDta.status = 'Status is Required !'
    }
    setError(errorDta)
    return errorDta
  }

  const changeHandle = (e) => {
    const clone = { ...initialValue }
    const value = e.target.value
    const name = e.target.name
    clone[name] = value
    setInitialValue(clone)
  }


  const getById = async (id) => {
    try {
      const res = await educationIDGet(id)
      setInitialValue(res.data);

    } catch (error) {

    }
  }


  const handleEdit = (id) => {
    setSelectedId(id); // Set the selected ID for editing
    setShow(true); // Open the modal
    getById(id); // Fetch data by ID to populate the form
  };

  const submitData = async () => {

    const validateResult = validation(initialValue);

    // If there are errors, focus on the first one
    if (Object.keys(validateResult).length) {
      for (const key in validateResult) {
        if (validateResult.hasOwnProperty(key)) {
          switch (key) {
            case 'degree':
              degreeRef.current.focus();
              break;
            case 'institution':
              institutionRef.current.focus();
              break;
            case 'start':
              startRef.current.focus();
              break;
            case 'end':
              endRef.current.focus();
              break;
            case 'status':
              statusRef.current.focus();
              break;
            default:
              break;
          }
          break;
        }
      }
      return;
    }

    try {
      setLoginSpin(true)
      // const res = await educationAdd(initialValue)
      // console.log(res?.data.error);
      const res = selectedId
        ? await educationUpdate(selectedId, initialValue)  // Update data
        : await educationAdd(initialValue);
      if (res?.error == false) {
        toastSuccessMessage(res?.message)
        setLoginSpin(false)
        getCmsReport()
        setShow(false)
      } else {
        toastSuccessMessageError(res?.message)
        setLoginSpin(false)
      }
    } catch (error) {
      setLoginSpin(false)
    }
  }




  // listData

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().substr(0, 10);
  };

  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  const [loading, setLoading] = useState(false);
  const [count] = useState(10);  // count is now a number 10
  const [page, setPage] = useState(0)
  const [totalCount, setTotalCount] = useState(null)
  const [data, setData] = useState(null)
  const [allData, setAllData] = useState(null)
  const [filterInitial, setFilterInitial] = useState({
    customer_mobile: '',
    end_date: getCurrentDate(),
    start_date: getCurrentDate(),
    txn_id: '',
  })

  const handleChange = (e) => {
    const clone = { ...filterInitial }
    const value = e.target.value
    const name = e.target.name
    clone[name] = value
    setFilterInitial(clone)
  }

  console.log(data);


  const getCmsReport = async (input) => {
    // console.log('getCmsReport called with input:', input);
    setLoading(true);
    const params = new URLSearchParams({
      ...filterInitial,
      count: count,  // count is a number 10
      page: input,
    })
    // console.log('API params:', params);
    try {
      const res = await getEducation();
      // console.log('API response:', res?.data);
      // setTotalCount(res?.data?.data?.total);
      setData(res?.data);
    } catch (error) {
      console.error('API error:', error);
    }
    setLoading(false);
  };

  const onChangeVal = (e) => {
    setPage(e - 1)
    getCmsReport(e - 1)
  };


  useEffect(() => {
    getCmsReport(0)
  }, [])



  // listData

  //delete

  const deleteItem = async (id) => {
    try {
      const res = await educationDelete(id)
      if (res?.error == false) {
        toastSuccessMessage(res?.message)
        setTimeout(() => {
          getCmsReport()
        }, 1000)
      } else {
        toastSuccessMessageError(res?.message)
      }

    } catch (error) {

    }
  }

  const confirm = (id) => {
    // console.log(e);
    // message.success('Click on Yes');
    deleteItem(id)
  };
  const cancel = (e) => {
    // console.log(e);
    message.error('ok');
  };

  //delete


  // useEffect(() => {
  //   getById()
  // }, [show])
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="mb-0 h6">Education Info/शिक्षा संबंधी जानकारी</h5>
          <div className="text-right">
            <a
              onClick={handleShow}
              href="javascript:void(0);"
              className="btn btn-sm btn-primary "
            >
              <i className="las mr-1 la-plus" />
              Add New
            </a>
          </div>
        </div>
        <div className="card-body">
          <table
            className="table aiz-table footable footable-1 breakpoint-xl"
            style={{}}
          >
            <thead>
              <tr className="footable-header">
                <th
                  className="footable-first-visible"
                  style={{ display: "table-cell" }}
                >
                  Degree/डिग्री
                </th>
                <th style={{ display: "table-cell" }}>Institution/संस्था</th>
                <th data-breakpoints="md" style={{ display: "table-cell" }}>
                  Start/शुरू
                </th>
                <th data-breakpoints="md" style={{ display: "table-cell" }}>
                  End/अंत
                </th>
                <th data-breakpoints="md" style={{ display: "table-cell" }}>
                  Status/स्थिति
                </th>
                <th
                  className="text-right footable-last-visible"
                  style={{ display: "table-cell" }}
                >
                  Options/विकल्प
                </th>
              </tr>
            </thead>

            <tbody>

              {data && data?.map((item) => {
                console.log(item);

                return <tr>
                  <td
                    className="footable-first-visible"
                    style={{ display: "table-cell" }}
                  >
                    {item?.degree}
                  </td>
                  <td style={{ display: "table-cell" }}>{item?.institution}</td>
                  <td style={{ display: "table-cell" }}>{item?.start}</td>
                  <td style={{ display: "table-cell" }}>{item?.end}</td>
                  <td style={{ display: "table-cell" }}>
                    {item?.status == true ? 'Active' : 'DeActive'}
                    {/* <label className="aiz-switch aiz-switch-success mb-0">
                      <input
                        type="checkbox"
                        id="status.0"
                        onchange="update_education_present_status(this)"
                        defaultValue={11}
                        defaultChecked
                        data-switch="success"
                      />
                      <span />
                    </label> */}
                  </td>
                  <td
                    className="text-right footable-last-visible"
                    style={{ display: "table-cell" }}
                  >
                    <a
                      href="javascript:void(0);"
                      className="btn btn-soft-info btn-icon btn-circle btn-sm"
                      onclick="education_edit_modal('11');"
                      title="Edit"
                      onClick={() => handleEdit(item._id)}
                    >
                      <i className="las la-edit" />
                    </a>
                    <Popconfirm
                      title="Delete the task"
                      description="Are you sure to delete?"
                      onConfirm={() => confirm(item?._id)}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div className="btn btn-soft-danger btn-icon btn-circle btn-sm confirm-delete"> <i className="las la-trash" /></div>

                    </Popconfirm>


                  </td>
                </tr>
              })}

            </tbody>
          </table>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <h5 className="modal-title h6">{selectedId ? 'Update' : 'Add'} Education Info/शिक्षा संबंधी जानकारी जोड़ें</h5>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <label className="col-md-3 col-form-label">
                Degree/डिग्री <span style={{ color: 'red' }}>*</span>
              </label>
              <div className="col-md-9">
                <input
                  type="text"
                  name="degree"

                  className="form-control"
                  placeholder="Degree"
                  ref={degreeRef}
                  value={initialValue.degree}
                  onChange={changeHandle}
                />

                {error?.degree && <p style={{ color: 'red', margin: '0px' }}>
                  {error?.degree}
                </p>}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-3 col-form-label">
                Institution/संस्था <span style={{ color: 'red' }}>*</span>
              </label>
              <div className="col-md-9">
                <input
                  type="text"
                  name="institution"

                  placeholder="Institution"
                  className="form-control"
                  ref={institutionRef}
                  value={initialValue.institution}
                  onChange={changeHandle}
                />
                {error?.institution && <p style={{ color: 'red', margin: '0px' }}>
                  {error?.institution}
                </p>}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-3 col-form-label">Start/शुरू <span style={{ color: 'red' }}>*</span></label>
              <div className="col-md-9">
                <input
                  type="number"
                  name="start"

                  className="form-control"
                  placeholder="Start"
                  ref={startRef}
                  value={initialValue.start}
                  onChange={changeHandle}
                />
                {error?.start && <p style={{ color: 'red', margin: '0px' }}>
                  {error?.start}
                </p>}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-3 col-form-label">End/अंत <span style={{ color: 'red' }}>*</span></label>
              <div className="col-md-9">
                <input
                  type="number"
                  name="end"
                  defaultValue={2015}
                  placeholder="End"
                  className="form-control"
                  ref={endRef}
                  value={initialValue.end}
                  onChange={changeHandle}
                />
                {error?.end && <p style={{ color: 'red', margin: '0px' }}>
                  {error?.end}
                </p>}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-3 col-form-label">Status/स्थिति <span style={{ color: 'red' }}>*</span></label>
              <div className="col-md-9">
                <select className="form-select" aria-label="Default select example" name="status" ref={statusRef} value={initialValue?.status} onChange={changeHandle}>
                  <option selected>Open this select menu</option>
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
                {error?.status && <p style={{ color: 'red', margin: '0px' }}>
                  {error?.status}
                </p>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={submitData}>
              {loadingSpin ? <Spinner animation="border" style={{ textAlign: 'center' }} variant="success" /> : `${selectedId ? 'Update' : 'Add'} Education Info`}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
}
export default EducationInfo;
