import { Link } from "react-router-dom"


function Blog() {
  return (
    <>
      <section className="pt-4 mb-4 py-lg-5-2">
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-6 text-center text-lg-left">
              <h1 className="fw-600 h4">Blog</h1>
            </div>
            <div className="col-lg-6">
              <ul className="breadcrumb bg-transparent p-0 justify-content-center justify-content-lg-end">
                <li className="breadcrumb-item opacity-50">
                  <a className="text-reset" href="https://demo.activeitzone.com/matrimonial">
                    Home
                  </a>
                </li>
                <li className="text-dark fw-600 breadcrumb-item">
                  <a className="text-reset" href="################blog">
                    "Blog"
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-4">
        <div className="container">
          <div className="card-columns">
            <div className="card mb-3 overflow-hidden shadow-sm">
              <a href="################blog/what-do-successful-relationships-and-successful-businesses-have-in-common" className="text-reset d-block">
                <img src="################public/uploads/all/uBDARQfJpAEFuUlutaNEZbg6cIU3QabJo32hTh01.png" data-src="################public/uploads/all/uBDARQfJpAEFuUlutaNEZbg6cIU3QabJo32hTh01.png" alt="What do successful relationships and successful businesses have in common?" className="img-fluid ls-is-cached lazyloaded" />
              </a>
              <div className="p-4">
                <h2 className="fs-18 fw-600 mb-1">
                  <a href="################blog/what-do-successful-relationships-and-successful-businesses-have-in-common" className="text-reset">
                    What do successful relationships and successful businesses have in common?
                  </a>
                </h2>
                <div className="mb-2 opacity-50">
                  <i>Weeding Tips</i>
                </div>
                <p className="opacity-70 mb-4">
                  The leaders have a vision for the future and meet regularly to plan how to achieve their goals. Most couples stop thinking critically about their relationship after the initial honeymoon stage.
                </p>
                <Link to="/blog/blog-details" className="btn btn-soft-primary">
                  View More
                </Link>
              </div>
            </div>
            <div className="card mb-3 overflow-hidden shadow-sm">
              <a href="################blog/what-does-a-good-relationship-look-like" className="text-reset d-block">
                <img src="################public/uploads/all/NbGjsZSmGSEOm25ZAG9RCJv9dYeP94Z2z0Dunwwj.png" data-src="################public/uploads/all/NbGjsZSmGSEOm25ZAG9RCJv9dYeP94Z2z0Dunwwj.png" alt="What Does a Good Relationship Look Like?" className="img-fluid ls-is-cached lazyloaded" />
              </a>
              <div className="p-4">
                <h2 className="fs-18 fw-600 mb-1">
                  <a href="################blog/what-does-a-good-relationship-look-like" className="text-reset">
                    What Does a Good Relationship Look Like?
                  </a>
                </h2>
                <div className="mb-2 opacity-50">
                  <i>Weeding Tips</i>
                </div>
                <p className="opacity-70 mb-4">
                  What does a good relationship look like?  In this post, we’ll take a look at what makes a good relationship.
                </p>
                <Link to="/blog/blog-details" className="btn btn-soft-primary">
                  View More
                </Link>
              </div>
            </div>
            <div className="card mb-3 overflow-hidden shadow-sm">
              <a href="################blog/5-things-you-can-do-to-improve-your-relationship-now" className="text-reset d-block">
                <img src="################public/uploads/all/ChVoOrArnZDuuTsd6VUkR7P8L15D4pKMzuiL9TQ0.png" data-src="################public/uploads/all/ChVoOrArnZDuuTsd6VUkR7P8L15D4pKMzuiL9TQ0.png" alt="5 Things You Can do to Improve your Relationship Now" className="img-fluid ls-is-cached lazyloaded" />
              </a>
              <div className="p-4">
                <h2 className="fs-18 fw-600 mb-1">
                  <a href="################blog/5-things-you-can-do-to-improve-your-relationship-now" className="text-reset">
                    5 Things You Can do to Improve your Relationship Now
                  </a>
                </h2>
                <div className="mb-2 opacity-50">
                  <i>Weeding Tips</i>
                </div>
                <p className="opacity-70 mb-4">
                  Change is possible in any relationship. But, it does require dedication and persistence.
                </p>
                <Link to="/blog/blog-details" className="btn btn-soft-primary">
                  View More
                </Link>
              </div>
            </div>
            <div className="card mb-3 overflow-hidden shadow-sm">
              <a href="################blog/marriage-advice-for-newlyweds" className="text-reset d-block">
                <img src="################public/uploads/all/tJAsCSW4HdIkyOs6Ny34OntGa8t9NaNgmOlNLPXU.png" data-src="################public/uploads/all/tJAsCSW4HdIkyOs6Ny34OntGa8t9NaNgmOlNLPXU.png" alt="Marriage Advice for Newlyweds" className="img-fluid ls-is-cached lazyloaded" />
              </a>
              <div className="p-4">
                <h2 className="fs-18 fw-600 mb-1">
                  <a href="################blog/marriage-advice-for-newlyweds" className="text-reset">
                    Marriage Advice for Newlyweds
                  </a>
                </h2>
                <div className="mb-2 opacity-50">
                  <i>Life Partner</i>
                </div>
                <p className="opacity-70 mb-4">
                  Getting married is an exciting time! What marriage advice for newlyweds will help keep your relationship strong over time?
                </p>
                <Link to="/blog/blog-details" className="btn btn-soft-primary">
                  View More
                </Link>
              </div>
            </div>
            <div className="card mb-3 overflow-hidden shadow-sm">
              <a href="################blog/give-and-take" className="text-reset d-block">
                <img src="################public/uploads/all/c8Nq9cfMecTVbcBW7K0HxsH7Zqw65choZnCu1pRg.png" data-src="################public/uploads/all/c8Nq9cfMecTVbcBW7K0HxsH7Zqw65choZnCu1pRg.png" alt="Give and Take" className="img-fluid ls-is-cached lazyloaded" />
              </a>
              <div className="p-4">
                <h2 className="fs-18 fw-600 mb-1">
                  <a href="################blog/give-and-take" className="text-reset">
                    Give and Take
                  </a>
                </h2>
                <div className="mb-2 opacity-50">
                  <i>Life Partner</i>
                </div>
                <p className="opacity-70 mb-4">
                  Relationships often require give and take. We’ve been learning that during our engagement and now in marriage.
                </p>
                <Link to="/blog/blog-details" className="btn btn-soft-primary">
                  View More
                </Link>
              </div>
            </div>
            <div className="card mb-3 overflow-hidden shadow-sm">
              <a href="################blog/what-does-a-good-relationship-look-like" className="text-reset d-block">
                <img src="################public/uploads/all/14Q4NZoCYMxIc33sY0ayS9SYH5Kf3fdCCGD59pB1.png" data-src="################public/uploads/all/14Q4NZoCYMxIc33sY0ayS9SYH5Kf3fdCCGD59pB1.png" alt="What Does a Good Relationship Look Like?" className="img-fluid ls-is-cached lazyloaded" />
              </a>
              <div className="p-4">
                <h2 className="fs-18 fw-600 mb-1">
                  <a href="################blog/what-does-a-good-relationship-look-like" className="text-reset">
                    What Does a Good Relationship Look Like?
                  </a>
                </h2>
                <div className="mb-2 opacity-50">
                  <i>Weeding Tips</i>
                </div>
                <p className="opacity-70 mb-4">
                  What does a good relationship look like? In this post, we’ll take a look at what makes a good relationship.
                </p>
                <Link to="/blog/blog-details" className="btn btn-soft-primary">
                  View More
                </Link>
              </div>
            </div>
          </div>
          <div className="aiz-pagination aiz-pagination-center mt-4">
          </div>
        </div>
      </section>


    </>
  )
}
export default Blog