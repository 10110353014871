// import Footer from "../../../components/common/footer/Footer";
import FooterMain from "../../../components/common/footer/FooterMain";

function FooterPage() {
  return (
    <>
      {/* <Footer /> */}
      <FooterMain/>
    </>
  );
}
export default FooterPage;
