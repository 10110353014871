import Form from 'react-bootstrap/Form';

import Career from "./career/Career";
import EducationInfo from "./educationInfo/EducationInfo";
import { ToastContainer } from 'react-toastify';
import PartnerExpectation from './partnerExpectation/PartnerExpectation';
import PhysicalAttributes from './physicalAttributes/PhysicalAttributes';
import PhysicalInformation from './physicalInformation/PhysicalInformation';
import { casteApi, cityApi, familyValueApi, getPhysicalInforApi, languageApi, martialStatusApi, memelanguageApi, onBehalfApi, religionApi, residenceCountryApi, stateApi, subCasteApi, UpdatePhysicalInforApi } from "../../../api/Api"
import { useEffect, useState } from 'react';
import BasicInformation from './basicInformation/BasicInformation';
import PresnoalInformation from './presnoalInformation/PresnoalInformation';
import IntroductionPage from './introdcutionPage/IntroductionPage';

function MyProfile() {
  const [residenceCountryData, setResidenceCountryData] = useState(null)
  const residenceCountry = async () => {

    try {
      const res = await residenceCountryApi()

      setResidenceCountryData(res?.data)

    } catch (error) {

    }

  }



  const [preferred_cityData, setPreferred_cityData] = useState(null)

  const preferred_city = async () => {

    try {
      const res = await cityApi()
      setPreferred_cityData(res?.data)

    } catch (error) {

    }

  }


  const [religionData, setReligionData] = useState(null)
  console.log(religionData);
  const religion = async () => {
    try {
      const res = await religionApi()
      setReligionData(res?.data)

    } catch (error) {

    }
  }

  const [casteData, setCasteData] = useState(null)


  const caste = async () => {
    try {
      const res = await casteApi()
      setCasteData(res?.data)

    } catch (error) {

    }
  }

  const [subCasteData, setSubCasteData] = useState(null)

  const subCaste = async () => {
    try {
      const res = await subCasteApi()
      setSubCasteData(res?.data)

    } catch (error) {

    }
  }




  const [languageData, setLanguageData] = useState(null)
  const language = async () => {
    try {
      const res = await languageApi()
      setLanguageData(res?.data)

    } catch (error) {

    }
  }

  const [memeLanguageData, setMemeLanguageData] = useState(null)
  const memeLanguage = async () => {
    try {
      const res = await memelanguageApi()
      setMemeLanguageData(res?.data)

    } catch (error) {

    }
  }



  const [preferred_stateData, setPreferred_stateData] = useState(null)
  const preferred_state = async () => {
    try {
      const res = await stateApi()
      setPreferred_stateData(res?.data)

    } catch (error) {

    }
  }

  const [familyValueData, setFamilyValueData] = useState(null)
  const family_value = async () => {
    try {
      const res = await familyValueApi()
      setFamilyValueData(res?.data)

    } catch (error) {

    }
  }

  const [martialStatusData, setMartialStatusData] = useState(null)
  const martialStatus = async () => {
    try {
      const res = await martialStatusApi()
      setMartialStatusData(res?.data)

    } catch (error) {

    }
  }

  const [onBehalfData, setOnBehalfData] = useState(null)
  // console.log(onBehalfData);

  const onBehalf = async () => {
    try {
      const res = await onBehalfApi()
      setOnBehalfData(res?.data)

    } catch (error) {

    }
  }


  useEffect(() => {
    residenceCountry()
    martialStatus()
    religion()
    caste()
    subCaste()
    language()
    preferred_state()
    family_value()
    memeLanguage()
    preferred_city()
    onBehalf()

  }, [])


  return (
    <>
      <div className="aiz-user-panel overflow-hidden">
        <IntroductionPage />

        {/* Personal Information */}
        <PresnoalInformation residenceCountryData={residenceCountryData} familyValueData={familyValueData} preferred_stateData={preferred_stateData} memeLanguageData={memeLanguageData} languageData={languageData} subCasteData={subCasteData} casteData={casteData} religionData={religionData} preferred_cityData={preferred_cityData} martialStatusData={martialStatusData} onBehalfData={onBehalfData} />
        {/* Present Address */}


        <EducationInfo />
        <Career />

        {/* Physical Attributes */}
        <PhysicalAttributes residenceCountryData={residenceCountryData} familyValueData={familyValueData} preferred_stateData={preferred_stateData} memeLanguageData={memeLanguageData} languageData={languageData} subCasteData={subCasteData} casteData={casteData} religionData={religionData} preferred_cityData={preferred_cityData} martialStatusData={martialStatusData} />

        {/* Hobbies  */}
        <BasicInformation residenceCountryData={residenceCountryData} familyValueData={familyValueData} preferred_stateData={preferred_stateData} memeLanguageData={memeLanguageData} languageData={languageData} subCasteData={subCasteData} casteData={casteData} religionData={religionData} preferred_cityData={preferred_cityData} martialStatusData={martialStatusData} />

        {/* Personal Attitude & Behavior */}

        {/* Residency Information */}

        {/* Spiritual & Social Background */}
        <PhysicalInformation residenceCountryData={residenceCountryData} familyValueData={familyValueData} preferred_stateData={preferred_stateData} memeLanguageData={memeLanguageData} languageData={languageData} subCasteData={subCasteData} casteData={casteData} religionData={religionData} preferred_cityData={preferred_cityData} martialStatusData={martialStatusData} />
        {/* Life Style */}

        {/* Astronomic Information  */}
        {/* Permanent Address */}
        {/* Family Information */}
        {/* Partner Expectation */}
        <PartnerExpectation
          residenceCountryData={residenceCountryData} familyValueData={familyValueData} preferred_stateData={preferred_stateData} memeLanguageData={memeLanguageData} languageData={languageData} subCasteData={subCasteData} casteData={casteData} religionData={religionData} preferred_cityData={preferred_cityData} martialStatusData={martialStatusData}
        />
      </div>
      <ToastContainer />
    </>
  );
}
export default MyProfile;
