import { useEffect, useState } from "react"
import { toastSuccessMessage, toastSuccessMessageError } from "../../../common/tostShow/TostShow"
import { cloudImage, presnolInfoGetApi, UpdatePresnolInfoApi } from "../../../../api/Api"


function PresnoalInformation({ residenceCountryData, preferred_cityData, religionData, casteData, subCasteData, languageData, memeLanguageData, preferred_stateData, familyValueData, martialStatusData, onBehalfData }) {
    const [initialValue, setInitialValue] = useState({
        firstname: "",
        lastname: "",
        gender: "",
        birth_date: "",
        namaz: "",
        zakaat: "",
        roza: "",
        occupation: "",
        income_range: "",
        family_background: "",
        on_behalf: "",
        hafiz: "",
        salah: "",
        marital_status: "",
        childrens: "2",
        health_details: "",
        country: "",
        state_id: "",
        city_id: "",
        postal_code: "",
        file: ''
    })


    const changeHandle = (e) => {
        const clone = { ...initialValue }
        const value = e.target.value
        const name = e.target.name
        clone[name] = value
        setInitialValue(clone)
    }

    const [profileImage, setProfileImage] = useState()
    console.log(profileImage);
    const imgs = new FormData();
    const [errorMessage, setErrorMessage] = useState('');
    // console.log(errorMessage);
    const [imgss, setImgss] = useState()
    console.log(imgss);
    const colodinaryImage = async (e) => {
        setProfileImage(e.target.files[0])
        imgs.append("image", e.target.files[0]);
        const allowedTypes = [
            'video/mp4',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/pdf',
            'application/x-rar-compressed',
            'application/x-zip-compressed',
            'application/zip'
        ];
        // console.log(e.target.files[0].type);
        if (e.target.files[0] && allowedTypes.includes(e.target.files[0].type)) {
            // setProfileImage(e.target.files[0])
            setErrorMessage('Invalid file type. Please select a valid file.');
        } else {
            setProfileImage(e.target.files[0])
            setErrorMessage('');
            try {
                const res = await cloudImage(imgs)
                // console.log(res?.data?.url);
                setTimeout(() => {
                    setImgss(res?.data?.url)
                    // if (res.data.error == false) {
                    //     updateProfile(res?.data?.data?.url)
                    // }
                }, 1000);
            } catch (error) {

            }
        }

    }



    const [profileData, setProfileData] = useState()
    console.log(profileData);

    const getDataProfile = async () => {
        try {
            const res = await presnolInfoGetApi()
            // console.log('dfvdf', res?.data);

            setProfileData(res?.data)
        } catch (error) {

        }
    }

    const updatePresnolInformation = async () => {
        const clone = { ...initialValue, file: imgss }
        console.log(clone);
        try {
            const res = await UpdatePresnolInfoApi(clone)
            // console.log(res);
            if (res?.error == false) {
                toastSuccessMessage(res?.message)
            } else {
                toastSuccessMessageError(res?.message)
            }

        } catch (error) {

        }
    }


    useEffect(() => {
        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
        const ob = {
            firstname: profileData?.firstname,
            lastname: profileData?.lastname,
            gender: profileData?.gender,
            birth_date: profileData?.birth_date ? formatDate(profileData?.birth_date) : "",
            namaz: profileData?.namaz,
            zakaat: profileData?.zakaat,
            roza: profileData?.roza,
            occupation: profileData?.occupation,
            income_range: profileData?.income_range,
            family_background: profileData?.family_background,
            on_behalf: profileData?.on_behalf,
            hafiz: profileData?.hafiz,
            salah: profileData?.salah,
            marital_status: profileData?.marital_status,
            childrens: profileData?.childrens,
            health_details: profileData?.health_details,
            country: profileData?.country,
            state_id: profileData?.state_id,
            city_id: profileData?.city_id,
            postal_code: profileData?.postal_code,
            file: profileData?.file
        }
        setInitialValue(ob)
        setImgss(profileData?.file)
    }, [profileData])

    useEffect(() => {
        getDataProfile()
    }, [])
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="mb-0 h6">Personal Information/व्यक्तिगत जानकारी</h5>
                </div>
                <div className="card-body">
                    <form
                        action="#"
                        method="POST"
                    >
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="first_name">
                                    First Name/पहला नाम
                                    <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    name="firstname"
                                    className="form-control"
                                    placeholder="First Name"
                                    value={initialValue.firstname}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="first_name">
                                    Last Name/उपनाम नाम
                                    <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    name="lastname"
                                    className="form-control"
                                    placeholder="Last Name"
                                    value={initialValue.lastname}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="first_name">
                                    Gender/लिंग
                                    <span className="text-danger">*</span>
                                </label>
                                <select
                                    class="form-select aiz-selectpicker form-selec"
                                    aria-label="Default select example"
                                    name="gender"
                                    value={initialValue.gender}
                                    onChange={changeHandle}
                                >
                                    <option selected>Open this select menu</option>
                                    <option value={'male'}>Male</option>
                                    <option value={'female'}>Female</option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="first_name">
                                    Date Of Birth/जन्म तिथि
                                    <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="date"
                                    className="aiz-date-range form-control"
                                    name="birth_date"
                                    placeholder=" Date Of Birth"
                                    value={initialValue.birth_date}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6 mt-2">
                                <label htmlFor="first_name">
                                    Namaz/नमाज
                                    <span className="text-danger">*</span>
                                </label>
                                <select
                                    class="form-select aiz-selectpicker form-selec"
                                    aria-label="Default select example"
                                    name="namaz"
                                    value={initialValue.namaz}
                                    onChange={changeHandle}
                                >
                                    <option selected>Open this select menu</option>
                                    <option value={'yes'}>Yes</option>
                                    <option value={'no'}>No</option>
                                    <option value={'sometimes'}>Sometimes</option>
                                </select>
                            </div>
                            <div className="col-md-6 mt-2">
                                <label htmlFor="first_name">
                                    Zakaat/ज़कातुल
                                    <span className="text-danger">*</span>
                                </label>
                                <select
                                    class="form-select aiz-selectpicker form-selec"
                                    aria-label="Default select example"
                                    name="zakaat"
                                    value={initialValue.zakaat}
                                    onChange={changeHandle}
                                >
                                    <option selected>Open this select menu</option>
                                    <option value={1}>Yes</option>
                                    <option value={2}>No</option>
                                    <option value={2}>sometimes</option>
                                </select>
                            </div>
                            <div className="col-md-6 mt-2">
                                <label htmlFor="first_name">
                                    Roza/रोजा
                                    <span className="text-danger">*</span>
                                </label>
                                <select
                                    class="form-select aiz-selectpicker form-selec"
                                    aria-label="Default select example"
                                    name="roza"
                                    value={initialValue.roza}
                                    onChange={changeHandle}
                                >
                                    <option selected>Open this select menu</option>
                                    <option value={'yes'}>Yes</option>
                                    <option value={'no'}>No</option>
                                    <option value={'sometimes'}>Sometimes</option>
                                </select>
                            </div>

                            <div className="col-md-6 mt-2">
                                <label htmlFor="first_name">
                                    Occupation/पेशा
                                    <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    name="occupation"
                                    className="form-control"
                                    placeholder="Occupation"
                                    value={initialValue.occupation}
                                    onChange={changeHandle}

                                />
                            </div>
                            <div className="col-md-6 mt-2">
                                <label htmlFor="first_name">
                                    Income Range/आय सीमा
                                    <span className="text-danger">*</span>
                                </label>
                                <select
                                    class="form-select aiz-selectpicker form-selec"
                                    aria-label="Default select example"
                                    name="income_range"
                                    value={initialValue.income_range}
                                    onChange={changeHandle}
                                >
                                    <option selected>Open this select menu</option>
                                    <option value={'monthly'}>Monthly</option>
                                    <option value={'annual'}>Annual</option>
                                </select>
                            </div>
                            <div className="col-md-6 mt-2">
                                <label htmlFor="first_name">
                                    Family Background/पारिवारिक पृष्ठभूमि
                                    <span className="text-danger">*</span>
                                </label>
                                <select
                                    class="form-select aiz-selectpicker form-selec"
                                    aria-label="Default select example"
                                    name="family_background"
                                    value={initialValue.family_background}
                                    onChange={changeHandle}
                                >
                                    <option selected>Open this select menu</option>
                                    <option value={'nuclear'}>Nuclear</option>
                                    <option value={'joint'}>Joint</option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="first_name">
                                    On Behalf/की ओर से
                                    <span className="text-danger">*</span>
                                </label>
                                <select className="form-select" aria-label="Default select example" name="on_behalf" value={initialValue.on_behalf}
                                    onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    {onBehalfData && onBehalfData?.map((item) => {
                                        return <option value={item?._id}>{item?.name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="col-md-6 mt-2">
                                <label htmlFor="first_name">
                                    Hafiz/Hafiz (if application)/हाफ़िज़/हाफ़िज़ (यदि आवेदन हो)
                                    <span className="text-danger">*</span>
                                </label>
                                <select
                                    class="form-select aiz-selectpicker form-selec"
                                    aria-label="Default select example"
                                    name="hafiz"
                                    value={initialValue.hafiz}
                                    onChange={changeHandle}
                                >
                                    <option selected>Open this select menu</option>
                                    <option value={'yes'}>Yes</option>
                                    <option value={'no'}>No</option>
                                </select>
                            </div>
                            <div className="col-md-6 mt-2">
                                <label htmlFor="first_name">
                                    Salah (Prayers) Regularity / सलाह (प्रार्थना) नियमितता
                                    <span className="text-danger">*</span>
                                </label>
                                <select
                                    class="form-select aiz-selectpicker form-selec"
                                    aria-label="Default select example"
                                    name="salah"
                                    value={initialValue.salah}
                                    onChange={changeHandle}
                                >
                                    <option selected>Open this select menu</option>
                                    <option value={'regular'}>Regular</option>
                                    <option value={'sometimes'}>Sometimes</option>
                                    <option value={'raely'}>Raely</option>
                                    <option value={'not specified'}>Not Specified</option>
                                </select>
                            </div>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="first_name">
                                    Marital Status/वैवाहिक स्थिति
                                    <span className="text-danger">*</span>
                                </label>
                                <select className="form-select" aria-label="Default select example" name="marital_status" value={initialValue.marital_status}
                                    onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    {martialStatusData && martialStatusData?.map((item) => {
                                        return <option value={item?._id}>{item?.status}</option>
                                    })}
                                </select>
                            </div>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="first_name">
                                    Number Of Children/बच्चों की संख्या
                                    <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    name="childrens"
                                    className="form-control"
                                    placeholder="Number Of Children"
                                    value={initialValue.childrens}
                                    onChange={changeHandle}
                                />
                            </div>



                            <div className="col-md-6 mt-3">
                                <label htmlFor="permanent_country_id">Country/देश <span className="text-danger">*</span></label>
                                <select className="form-select" aria-label="Default select example" name="country" value={initialValue.country}
                                    onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    {residenceCountryData && residenceCountryData?.map((item) => {
                                        return <option value={item?._id}>{item?.name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="permanent_state_id">State/राज्य <span className="text-danger">*</span></label>
                                <select className="form-select" aria-label="Default select example" name="state_id" value={initialValue.state_id}
                                    onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    {preferred_stateData && preferred_stateData?.map((item) => {
                                        return <option value={item?._id}>{item?.name}</option>
                                    })}
                                </select>

                            </div>


                            <div className="col-md-6 mt-3">
                                <label htmlFor="permanent_city_id">City/शहर <span className="text-danger">*</span></label>
                                <select className="form-select" aria-label="Default select example" name="city_id" value={initialValue.city_id}
                                    onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    {preferred_cityData && preferred_cityData?.map((item) => {
                                        return <option value={item?._id}>{item?.name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="permanent_postal_code">Postal Code/डाक कोड <span className="text-danger">*</span></label>
                                <input
                                    type="number"
                                    name="postal_code"
                                    className="form-control"
                                    placeholder="Postal Code"
                                    value={initialValue.postal_code}
                                    onChange={changeHandle}

                                />
                            </div>
                            <div className="col-md-6 mt-2">
                                <label htmlFor="first_name">
                                    Health Details/स्वास्थ्य विवरण
                                    <span className="text-danger">*</span>
                                </label>
                                {/* <input
                    type="text"
                    name="children"
                    defaultValue={0}
                    className="form-control"
                    placeholder="Number Of Children"
                    fdprocessedid="omeai6"
                  /> */}

                                <textarea name="health_details" id="" className="form-control" placeholder='Enter Health Details' value={initialValue.health_details}
                                    onChange={changeHandle}></textarea>
                            </div>
                        </div>
                        <div className="form-group row">



                            <div className="col-md-6 mt-2">
                                <label htmlFor="photo">
                                    photo/तस्वीर <small>(800x800)</small>
                                    <small className="text-danger">(Approved.)</small>
                                    <span className="text-danger">*</span>
                                </label>
                                <div
                                    className="input-group"
                                >
                                    <div className="form-control file-amount">
                                        <input
                                            type="file"
                                            name="photo"
                                            className="selected-files"
                                            onChange={colodinaryImage}
                                        />
                                    </div>
                                </div>
                                <div className="file-preview box sm">
                                    <div
                                        className="d-flex justify-content-between align-items-center mt-2 file-preview-item"
                                    >
                                        <div className="align-items-center align-self-stretch d-flex justify-content-center thumb">
                                            <img
                                                src={imgss && imgss ? imgss : 'https://cdn2.iconfinder.com/data/icons/lucid-generic/24/User_person_avtar_profile_picture_dp-512.png'}
                                                className="img-fit"
                                            />
                                        </div>
                                        {/* <div className="col body">
                                            <h6 className="d-flex">
                                                <span className="text-truncate title">1</span>
                                                <span className="ext">.png</span>
                                            </h6>
                                            <p>869 KB</p>
                                        </div>
                                        <div className="remove">
                                            <button
                                                className="btn btn-sm btn-link remove-attachment"
                                                type="button"
                                                fdprocessedid="usldk"
                                            >
                                                <i className="la la-close" />
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-right">
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={updatePresnolInformation}
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default PresnoalInformation