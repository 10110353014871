import PackegePurchaseHistory from "../../../../components/dashboard/packages/PackegePurchaseHistory/PackegePurchasHistory"


function PackegePurchaseHistoryPage() {
    return (
        <>
        <PackegePurchaseHistory/>
        </>
    )
}
export default PackegePurchaseHistoryPage