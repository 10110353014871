import WalletWithdrowRequest from "../../../../components/dashboard/referalSystem/WalletWithdrowRequest/WalletWithdrowRequest"


function WalletWithdrowRequestPage() {
    return (
        <>
        <WalletWithdrowRequest/>
        </>
    )
}
export default WalletWithdrowRequestPage