
import { Col, Nav, Row, Tab } from "react-bootstrap";
import '../message/message.css';
import { useState } from "react";

function Message() {
  const [messages, setMessages] = useState([
    { type: 'sent', content: 'hello', time: '1:00 pm' },
    { type: 'received', content: 'hello', time: '1:01 pm' },
  ]);
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.trim() !== '') {
      setMessages([...messages, { type: 'sent', content: input, time: '1:02 pm' }]);
      setInput('');
    }
  };

  return (
    <div className="whatsapp-web">
      <div className="sidebar">
        <div className="sidebar-header">
          <input type="text" placeholder="Search" className="search-bar" />
        </div>
        <div className="chat-list">
          <div className="chat-item">
            <img src="user1.jpg" alt="User" className="avatar" />
            <div className="chat-details">
              <h4>Ashraf</h4>
              <p>hello</p>
            </div>
            <div className="chat-time">
              <span>1:02 pm</span>
            </div>
          </div>
          {/* Add more chat items as needed */}
        </div>
      </div>

      <div className="chat-area">
        <div className="chat-header">
          <img src="user1.jpg" alt="User" className="avatar" />
          <h4>Ashraf</h4>
        </div>
        <div className="messages">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.type}`}>
              <p>{message.content}</p>
              <span>{message.time}</span>
            </div>
          ))}
        </div>
        <div className="chat-input">
          <input
            type="text"
            placeholder="Type a message"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          />
          <button onClick={handleSend} className="send-button">Send</button>
        </div>
      </div>
    </div>
  );
}

export default Message;