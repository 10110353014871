import { useEffect, useState } from "react"
import { basicInfoGetApi, UpdateBasicInfoGetApi } from "../../../../api/Api"
import { toastSuccessMessage, toastSuccessMessageError } from "../../../common/tostShow/TostShow"


function BasicInformation({ residenceCountryData, preferred_cityData, religionData, casteData, subCasteData, languageData, memeLanguageData, preferred_stateData, familyValueData, martialStatusData }) {
    const [initialValue, setInitialValue] = useState({
        hobbies: "",
        intrests: "",
        music: "",
        books: "",
        movies: "",
        tv_shows: "",
        sports: "",
        fitness_activitiess: "",
        cuisines: "",
        dress_style: "",
        affection: "",
        humor: "",
        political_views: "",
        religious_service: "",
        diet: "",
        drink: "",
        smoke: "",
        living_with: "",
        sun_sign: "",
        moon_sign: "",
        time_of_birth: "",
        city_of_birth: "",
        father: "",
        mother: "",
        sibling: "",

    })


    const changeHandle = (e) => {
        const clone = { ...initialValue }
        const value = e.target.value
        const name = e.target.name
        clone[name] = value
        setInitialValue(clone)
    }

    const [profileData, setProfileData] = useState()
    // console.log(profileData);

    const getDataProfile = async () => {
        try {
            const res = await basicInfoGetApi()
            // console.log('dfvdf', res?.data);

            setProfileData(res?.data)
        } catch (error) {

        }
    }

    const upadteBasicInformation = async () => {
        // console.log(initialValue);

        try {
            const res = await UpdateBasicInfoGetApi(initialValue)
            console.log(res);
            if (res?.error == false) {
                toastSuccessMessage(res?.message)
            } else {
                toastSuccessMessageError(res?.message)
            }

        } catch (error) {

        }
    }


    useEffect(() => {
        const ob = {
            hobbies: profileData?.hobbies,
            intrests: profileData?.intrests,
            music: profileData?.music,
            books: profileData?.books,
            movies: profileData?.movies,
            tv_shows: profileData?.tv_shows,
            sports: profileData?.sports,
            fitness_activitiess: profileData?.fitness_activitiess,
            cuisines: profileData?.cuisines,
            dress_style: profileData?.dress_style,
            affection: profileData?.affection,
            humor: profileData?.humor,
            political_views: profileData?.political_views,
            religious_service: profileData?.religious_service,
            diet: profileData?.diet,
            drink: profileData?.drink,
            smoke: profileData?.smoke,
            living_with: profileData?.living_with,
            sun_sign: profileData?.sun_sign,
            moon_sign: profileData?.moon_sign,
            time_of_birth: profileData?.time_of_birth,
            city_of_birth: profileData?.city_of_birth,
            father: profileData?.father,
            mother: profileData?.mother,
            sibling: profileData?.sibling,
        }
        setInitialValue(ob)
    }, [profileData])

    useEffect(() => {
        getDataProfile()
    }, [])
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="mb-0 h6">Basic Information ( Hobbies &amp; Interest , Personal Attitude &amp; Behavior , Lifestyle , Astronomic Information , Family Information )/बुनियादी जानकारी (शौक और रुचि, व्यक्तिगत दृष्टिकोण और व्यवहार, जीवन शैली, खगोलीय जानकारी, पारिवारिक जानकारी)</h5>
                </div>
                <div className="card-body">
                    <form
                        action="################hobbies/42"
                        method="POST"
                    >
                        <input name="_method" type="hidden" defaultValue="PATCH" />
                        <input
                            type="hidden"
                            name="_token"
                            defaultValue="ndobqdxEZbPqJWJqRXNT08F78xizcFVc9B3hkWuy"
                        />{" "}
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="hobbies">Hobbies/शौक</label>
                                <input
                                    type="text"
                                    name="hobbies"
                                    className="form-control"
                                    placeholder="Hobbies"
                                    value={initialValue?.hobbies}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="interests">Interests/रुचियाँ</label>
                                <input
                                    type="text"
                                    name="intrests"
                                    placeholder="Interests"
                                    className="form-control"
                                    value={initialValue?.intrests}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="music">Music/संगीत</label>
                                <input
                                    type="text"
                                    name="music"
                                    className="form-control"
                                    placeholder="Music"
                                    value={initialValue?.music}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="books">Books/किताबें</label>
                                <input
                                    type="text"
                                    name="books"
                                    placeholder="Books"
                                    className="form-control"
                                    value={initialValue?.books}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="movies">Movies/चलचित्र</label>
                                <input
                                    type="text"
                                    name="movies"
                                    className="form-control"
                                    placeholder="Movies"
                                    value={initialValue?.movies}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="tv_shows">TV Shows/टीवी शो</label>
                                <input
                                    type="text"
                                    name="tv_shows"
                                    className="form-control"
                                    placeholder="TV Shows"
                                    value={initialValue?.tv_shows}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="sports">Sports/खेल</label>
                                <input
                                    type="text"
                                    name="sports"
                                    className="form-control"
                                    placeholder="Sports"
                                    value={initialValue?.sports}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="fitness_activities">
                                    Fitness Activitiess/फिटनेस गतिविधियाँ
                                </label>
                                <input
                                    type="text"
                                    name="fitness_activitiess"
                                    placeholder="Fitness Activities"
                                    className="form-control"
                                    value={initialValue?.fitness_activitiess}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="cuisines">Cuisines/व्यंजनों</label>
                                <input
                                    type="text"
                                    name="cuisines"
                                    defaultValue="Open-minded"
                                    className="form-control"
                                    placeholder="Cuisines"
                                    value={initialValue?.cuisines}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="dress_styles">Dress Styles/पोशाक शैलियाँ</label>
                                <input
                                    type="text"
                                    name="dress_style"
                                    defaultValue="Midi Dress,Off the Shoulder"
                                    placeholder="Dress Styles"
                                    className="form-control"
                                    value={initialValue?.dress_style}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="affection">Affection/स्नेह</label>
                                <input
                                    type="text"
                                    name="affection"
                                    defaultValue="Tender attachment"
                                    className="form-control"
                                    placeholder="Affection"
                                    value={initialValue?.affection}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="humor">Humor/हास्य</label>
                                <input
                                    type="text"
                                    name="humor"
                                    defaultValue="Incongruity, Slapstick"
                                    placeholder="Humor"
                                    className="form-control"
                                    value={initialValue?.humor}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="political_views">Political Views/राजनीतिक दृष्टिकोण</label>
                                <input
                                    type="text"
                                    name="political_views"
                                    defaultValue="Not interested"
                                    className="form-control"
                                    placeholder="Political Views"
                                    value={initialValue?.political_views}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="religious_service">Religious Service/धार्मिक सेवा</label>
                                <input
                                    type="text"
                                    name="religious_service"
                                    defaultValue="Not interested"
                                    placeholder="Religious Service"
                                    className="form-control"
                                    value={initialValue?.religious_service}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="diet">Diet/आहार</label>
                                <select
                                    class="form-select aiz-selectpicker"
                                    aria-label="Default select example"
                                    name="diet"
                                    value={initialValue.diet}
                                    onChange={changeHandle}
                                >
                                    <option selected>Open this select menu</option>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>

                            </div>
                            <div className="col-md-6">
                                <label htmlFor="drink">Drink/दारु</label>
                                <select
                                    class="form-select aiz-selectpicker"
                                    aria-label="Default select example"
                                    name="drink"
                                    value={initialValue.drink}
                                    onChange={changeHandle}

                                >
                                    <option selected>Open this select menu</option>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="smoke">Smoke/धुआँ</label>
                                <select
                                    class="form-select aiz-selectpicker"
                                    aria-label="Default select example"
                                    name="smoke"
                                    value={initialValue.smoke}
                                    onChange={changeHandle}
                                >
                                    <option selected>Open this select menu</option>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="living_with">Living With/रह रहे हैं</label>
                                <input
                                    type="text"
                                    name="living_with"
                                    placeholder="Living With"
                                    className="form-control"
                                    value={initialValue?.living_with}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="father">Father/पिता</label>
                                <input
                                    type="text"
                                    name="father"
                                    className="form-control"
                                    placeholder="Father"
                                    value={initialValue?.father}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="mother">Mother/माता</label>
                                <input
                                    type="text"
                                    name="mother"
                                    placeholder="Mother"
                                    className="form-control"
                                    value={initialValue?.mother}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">

                            <div className="col-md-6">
                                <label htmlFor="sibling">Sibling/भाई-बहन</label>
                                <input
                                    type="text"
                                    name="sibling"
                                    className="form-control"
                                    placeholder="Sibling"
                                    value={initialValue?.sibling}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="sun_sign">Sun Sign/सूर्य चिन्ह</label>
                                <input
                                    type="text"
                                    name="sun_sign"
                                    className="form-control"
                                    placeholder="Sun Sign"
                                    value={initialValue?.sun_sign}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="moon_sign">Moon Sign/चंद्र चिन्ह</label>
                                <input
                                    type="text"
                                    name="moon_sign"
                                    className="form-control"
                                    placeholder="Sun Sign"
                                    value={initialValue?.moon_sign}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6 mt-3">
                                <label htmlFor="time_of_birth">Time Of Birth/जन्म का समय</label>
                                <input
                                    type="time"
                                    name="time_of_birth"
                                    className="form-control"
                                    placeholder="Time Of Birth"
                                    value={initialValue?.time_of_birth}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">

                            <div className="col-md-6">
                                <label htmlFor="city_of_birth">City Of Birth/जन्म का शहर</label>
                                <input
                                    type="text"
                                    name="city_of_birth"
                                    placeholder="City Of Birth"
                                    className="form-control"
                                    value={initialValue?.city_of_birth}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="text-right">
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={upadteBasicInformation}
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default BasicInformation