import { useEffect, useState } from "react"
import { toastSuccessMessage, toastSuccessMessageError } from "../../../common/tostShow/TostShow"
import { introPageGetApi, UpdateIntroPageApi } from "../../../../api/Api"

function IntroductionPage() {
    const [initialValue, setInitialValue] = useState({
        mobile: '',
        email: '',
        introduction: ''
    })


    const changeHandle = (e) => {
        const clone = { ...initialValue }
        const value = e.target.value
        const name = e.target.name
        clone[name] = value
        setInitialValue(clone)
    }

    const [profileData, setProfileData] = useState()
    // console.log(profileData);

    const getDataProfile = async () => {
        try {
            const res = await introPageGetApi()
            // console.log('dfvdf', res?.data);

            setProfileData(res?.data)
        } catch (error) {

        }
    }

    const updateIntroPage = async () => {
        // console.log(initialValue);

        try {
            const res = await UpdateIntroPageApi(initialValue)
            // console.log(res);
            if (res?.error == false) {
                toastSuccessMessage(res?.message)
            } else {
                toastSuccessMessageError(res?.message)
            }

        } catch (error) {

        }
    }


    useEffect(() => {
        const ob = {
            mobile: profileData?.mobile,
            email: profileData?.email,
            introduction: profileData?.introduction
        }
        setInitialValue(ob)
    }, [profileData])

    useEffect(() => {
        getDataProfile()
    }, [])
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="mb-0 h6">Introduction , Email , Mobile / परिचय, ईमेल, मोबाइल</h5>
                </div>
                <div className="card-body">
                    <form
                        action="################members/introduction_update/17"
                        method="POST"
                    >
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="email">Email/ईमेल</label>
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    placeholder="Email"
                                    value={initialValue?.email}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="mobile">Mobile Number/मोबाइल नंबर</label>
                                <input
                                    type="number"
                                    name="mobile"
                                    placeholder="Mobile"
                                    className="form-control"
                                    value={initialValue?.mobile}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12">
                                <label >Introduction/परिचय</label>
                                <textarea
                                    type="text"
                                    name="introduction"
                                    className="form-control"
                                    rows={4}
                                    placeholder="Introduction"
                                    value={initialValue?.introduction}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="text-right">
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={updateIntroPage}
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default IntroductionPage