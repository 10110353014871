import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { casteApi, familyValueApi, getprofileApi, languageApi, martialStatusApi, religionApi, residenceCountryApi, stateApi, subCasteApi, UpdatePartnerExpactetionApi } from "../../../../api/Api"
import { toastSuccessMessage, toastSuccessMessageError } from "../../../common/tostShow/TostShow"


function PartnerExpectation({ residenceCountryData, preferred_cityData, religionData, casteData, subCasteData, languageData, memeLanguageData, preferred_stateData, familyValueData, martialStatusData }) {

    const [initialValue, setInitialValue] = useState({
        general_requirement: "",
        residence_country: "",
        min_height: "",
        max_weight: "",
        children_acceptable: "",
        religion: "",
        caste: "",
        sub_caste: "",
        lang: "",
        education: "",
        profession: "",
        smoking_acceptable: "",
        drinking_acceptable: "",
        dating_acceptable: "",
        body_type: "",
        personal_value: "",
        manglik: "",
        preferred_country: "",
        preferred_state: "",
        family_value: "",
        complexion: "",
        marital_status: ''
    })


    const changeHandle = (e) => {
        const clone = { ...initialValue }
        const value = e.target.value
        const name = e.target.name
        clone[name] = value
        setInitialValue(clone)
    }

    const [profileData, setProfileData] = useState()
    // console.log(profileData);

    const getDataProfile = async () => {
        try {
            const res = await getprofileApi()
            // console.log('dfvdf', res?.data);

            setProfileData(res?.data)
        } catch (error) {

        }
    }

    const upadtePartnerExpactetion = async () => {
        // console.log(initialValue);

        try {
            const res = await UpdatePartnerExpactetionApi(initialValue)
            console.log(res);
            if (res?.error == false) {
                toastSuccessMessage(res?.message)
            } else {
                toastSuccessMessageError(res?.message)
            }

        } catch (error) {

        }
    }


    useEffect(() => {
        const ob = {
            general_requirement: profileData?.general_requirement,
            residence_country: profileData?.residence_country,
            min_height: profileData?.min_height,
            max_weight: profileData?.max_weight,
            children_acceptable: profileData?.children_acceptable,
            religion: profileData?.religion,
            caste: profileData?.caste,
            sub_caste: profileData?.sub_caste,
            lang: profileData?.lang,
            education: profileData?.education,
            profession: profileData?.profession,
            smoking_acceptable: profileData?.smoking_acceptable,
            drinking_acceptable: profileData?.drinking_acceptable,
            dating_acceptable: profileData?.dating_acceptable,
            body_type: profileData?.body_type,
            personal_value: profileData?.personal_value,
            manglik: profileData?.manglik,
            preferred_country: profileData?.preferred_country,
            preferred_state: profileData?.preferred_state,
            family_value: profileData?.family_value,
            complexion: profileData?.complexion,
            marital_status: profileData?.marital_status
        }
        setInitialValue(ob)
    }, [profileData])

    useEffect(() => {
        getDataProfile()
    }, [])
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="mb-0 h6">Partner Expectation/साथी की अपेक्षा</h5>
                </div>
                <div className="card-body">
                    <form
                        action="################partner_expectations/42"
                        method="POST"
                    >
                        <input name="_method" type="hidden" defaultValue="PATCH" />
                        <input
                            type="hidden"
                            name="_token"
                            defaultValue="ndobqdxEZbPqJWJqRXNT08F78xizcFVc9B3hkWuy"
                        />{" "}
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="general">General Requirement/सामान्य आवश्यकता</label>
                                <input
                                    type="text"
                                    name="general_requirement"
                                    className="form-control"
                                    placeholder="General"
                                    value={initialValue.general_requirement}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="residence_country_id">
                                    Residence Country/निवास देश
                                </label>
                                <select class="form-control" aria-label="Default select example" name="residence_country" value={initialValue.residence_country}
                                    onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    {residenceCountryData && residenceCountryData?.map((item) => {
                                        return <option value={item?._id}>{item?.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="partner_height">Min Height (In Feet)/न्यूनतम ऊंचाई (फीट में)</label>
                                <input
                                    type="number"
                                    name="min_height"
                                    placeholder="Height"
                                    className="form-control"
                                    value={initialValue.min_height}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="partner_weight">Max Weight (In Kg)/अधिकतम वजन (किलो में)</label>
                                <input
                                    type="number"
                                    name="max_weight"
                                    className="form-control"
                                    placeholder="Weight"
                                    value={initialValue.max_weight}
                                    onChange={changeHandle}

                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="partner_marital_status">Marital Status/वैवाहिक स्थिति</label>
                                <select class="form-control" aria-label="Default select example" name="marital_status" value={initialValue.marital_status}
                                    onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    {martialStatusData && martialStatusData?.map((item) => {
                                        return <option value={item?._id}>{item?.status}</option>
                                    })}
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="partner_children_acceptable">
                                    Children Acceptable/बच्चे स्वीकार्य
                                </label>
                                <select class="form-select" aria-label="Default select example" name="children_acceptable" value={initialValue.children_acceptable} onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                    <option value="dose_not_matter">Dose not matter</option>
                                </select>

                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="partner_religion_id">Religion/धर्म</label>
                                <select className="form-select" aria-label="Default select example" name="religion" value={initialValue.religion}
                                    onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    {religionData && religionData?.map((item) => {
                                        return <option value={item?._id}>{item?.religion}</option>
                                    })}
                                </select>

                            </div>
                            <div className="col-md-6">
                                <label htmlFor="partner_caste_id">Caste/जाति</label>
                                <select className="form-select" aria-label="Default select example" name="caste" value={initialValue.caste}
                                    onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    {casteData && casteData?.map((item) => {
                                        return <option value={item?._id}>{item?.caste}</option>
                                    })}
                                </select>

                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="partner_sub_caste_id">Sub Caste/उप जाति</label>
                                <select className="form-select" aria-label="Default select example" name="sub_caste" value={initialValue.sub_caste}
                                    onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    {subCasteData && subCasteData?.map((item) => {
                                        return <option value={item?._id}>{item?.name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="language_id">Language/भाषा</label>
                                <select className="form-select" aria-label="Default select example" name="lang" value={initialValue.lang}
                                    onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    {languageData && languageData?.map((item) => {
                                        return <option value={item?._id}>{item?.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="pertner_education">Education/शिक्षा</label>
                                <input
                                    type="text"
                                    name="education"
                                    className="form-control"
                                    placeholder="Education"
                                    value={initialValue.education}
                                    onChange={changeHandle}

                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="partner_profession">Profession/पेशा</label>
                                <input
                                    type="text"
                                    name="profession"
                                    className="form-control"
                                    value={initialValue.profession}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="smoking_acceptable">Smoking Acceptable/धूम्रपान स्वीकार्य</label>
                                <select
                                    class="form-select aiz-selectpicker"
                                    aria-label="Default select example"
                                    name="smoking_acceptable"
                                    value={initialValue.smoking_acceptable}
                                    onChange={changeHandle}
                                >
                                    <option value="no" selected>No</option>
                                    <option value="yes">Yes</option>
                                    <option value="dose_not_matter" >
                                        Dose not matter
                                    </option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="drinking_acceptable">
                                    Drinking Acceptable/शराब स्वीकार्य
                                </label>
                                <select
                                    class="form-select aiz-selectpicker"

                                    name="drinking_acceptable"
                                    value={initialValue.drinking_acceptable}
                                    onChange={changeHandle}
                                >
                                    <option value="no" selected>No</option>
                                    <option value="yes">Yes</option>
                                    <option value="dose_not_matter" >
                                        Dose not matter
                                    </option>
                                </select>

                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="partner_diet">Dieting Acceptable/आहार स्वीकार्य</label>
                                <select
                                    class="form-select aiz-selectpicker"

                                    name="dating_acceptable"
                                    value={initialValue.dating_acceptable}
                                    onChange={changeHandle}
                                >
                                    <option value="no" selected>No</option>
                                    <option value="yes">Yes</option>
                                    <option value="dose_not_matter" >
                                        Dose not matter
                                    </option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="partner_body_type">Body Type/शारीरिक प्रकार</label>
                                <input
                                    type="text"
                                    name="body_type"
                                    className="form-control"
                                    placeholder="Body Type"
                                    value={initialValue.body_type}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="partner_personal_value">Personal Value/व्यक्तिगत मूल्य</label>
                                <input
                                    type="text"
                                    name="personal_value"
                                    className="form-control"
                                    placeholder="Personal Value"
                                    value={initialValue.personal_value}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="partner_manglik">Manglik/मांगलिक</label>
                                <select
                                    class="form-select aiz-selectpicker"
                                    name="manglik"
                                    value={initialValue.manglik}
                                    onChange={changeHandle}
                                >
                                    <option value="no" selected>No</option>
                                    <option value="yes">Yes</option>
                                    <option value="dose_not_matter" >
                                        Dose not matter
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="partner_country_id">Preferred Country/पसंदीदा देश</label>
                                <select className="form-select" aria-label="Default select example" name="preferred_country" value={initialValue.preferred_country}
                                    onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    {residenceCountryData && residenceCountryData?.map((item) => {
                                        return <option value={item?._id}>{item?.name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="partner_state_id">Preferred State/पसंदीदा राज्य</label>
                                <select className="form-select" aria-label="Default select example" name="preferred_state" value={initialValue.preferred_state}
                                    onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    {preferred_stateData && preferred_stateData?.map((item) => {
                                        return <option value={item?._id}>{item?.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="family_value_id">Family Value/पारिवारिक मूल्य</label>
                                <select className="form-select" aria-label="Default select example" name="family_value" value={initialValue.family_value}
                                    onChange={changeHandle}>
                                    <option selected>Open this select menu</option>
                                    {familyValueData && familyValueData?.map((item) => {
                                        return <option value={item?._id}>{item?.name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="pertner_complexion">Complexion/रंग</label>
                                <input
                                    type="text"
                                    name="complexion"
                                    className="form-control"
                                    placeholder="Complexion"
                                    value={initialValue.complexion}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="text-right">
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={upadtePartnerExpactetion}
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default PartnerExpectation