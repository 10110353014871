import MyProfile from "../../../components/dashboard/myProfile/MyProfile";

function ManageProfilePage() {
  return (
    <>
      <MyProfile />
    </>
  );
}
export default ManageProfilePage;
