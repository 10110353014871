import { useEffect, useState } from "react"
import { physicalAttributeGetApi, UpdatePhysicalAttributeApi } from "../../../../api/Api"
import { toastSuccessMessage, toastSuccessMessageError } from "../../../common/tostShow/TostShow"


function PhysicalAttributes() {
    const [initialValue, setInitialValue] = useState({
        height: "",
        weight: "",
        eye_color: "",
        hair_color: "",
        complexion: "",
        body_type: "",
        body_art: "",
        blood_group: '',
        disability: "",
    })




    const changeHandle = (e) => {
        const clone = { ...initialValue }
        const value = e.target.value
        const name = e.target.name
        clone[name] = value
        setInitialValue(clone)
    }

    const [profileData, setProfileData] = useState()
    console.log(profileData);

    const getDataProfile = async () => {
        try {
            const res = await physicalAttributeGetApi()
            console.log(res?.data);

            setProfileData(res?.data)
        } catch (error) {

        }
    }

    const upadtePhysicalAttribute = async () => {
        console.log(initialValue);

        try {
            const res = await UpdatePhysicalAttributeApi(initialValue)
            if (res?.error == false) {
                toastSuccessMessage(res?.message)
            } else {
                toastSuccessMessageError(res?.message)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        const ob = {
            height: profileData?.height,
            weight: profileData?.weight,
            eye_color: profileData?.eye_color,
            hair_color: profileData?.hair_color,
            complexion: profileData?.complexion,
            body_type: profileData?.body_type,
            body_art: profileData?.body_art,
            blood_group: profileData?.blood_group,
            disability: profileData?.disability,
        }
        setInitialValue(ob)
    }, [profileData])

    useEffect(() => {
        getDataProfile()
    }, [])
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h5 className="mb-0 h6">Physical Attributes/भौतिक गुण</h5>
                </div>
                <div className="card-body">
                    <form
                        action="################physical-attribute/42"
                        method="POST"
                    >
                        <input name="_method" type="hidden" defaultValue="PATCH" />
                        <input
                            type="hidden"
                            name="_token"
                            defaultValue="ndobqdxEZbPqJWJqRXNT08F78xizcFVc9B3hkWuy"
                        />{" "}
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="height">Height (In Feet)/ऊंचाई (फीट में)</label>
                                <input
                                    type="number"
                                    name="height"
                                    className="form-control"
                                    placeholder="Height"
                                    value={initialValue.height}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="weight">Weight (In Kg)/वजन (किलो में)</label>
                                <input
                                    type="number"
                                    name="weight"
                                    placeholder="Weight"
                                    className="form-control"
                                    value={initialValue.weight}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="eye_color">Eye color/आँखों का रंग</label>
                                <input
                                    type="text"
                                    name="eye_color"
                                    className="form-control"
                                    placeholder="Eye color"
                                    value={initialValue.eye_color}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="hair_color">Hair Color/बालों का रंग</label>
                                <input
                                    type="text"
                                    name="hair_color"
                                    placeholder="Hair Color"
                                    className="form-control"
                                    value={initialValue.hair_color}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="complexion">Complexion/रंग</label>
                                <input
                                    type="text"
                                    name="complexion"
                                    className="form-control"
                                    placeholder="Complexion"
                                    value={initialValue.complexion}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="blood_group">Blood Group/ब्लड ग्रुप</label>
                                <input
                                    type="text"
                                    name="blood_group"
                                    placeholder="Blood Group"
                                    className="form-control"
                                    value={initialValue.blood_group}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="body_type">Body Type/शरीर के प्रकार</label>
                                <input
                                    type="text"
                                    name="body_type"

                                    className="form-control"
                                    placeholder="Body Type"
                                    value={initialValue?.body_type}
                                    onChange={changeHandle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="body_art">Body Art/शरीर कला</label>
                                <input
                                    type="text"
                                    name="body_art"
                                    placeholder="Body Art"
                                    className="form-control"
                                    value={initialValue.body_art}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label htmlFor="disability">Disability/विकलांगता</label>
                                <input
                                    type="text"
                                    name="disability"
                                    defaultValue="Recusandae Est nost"
                                    className="form-control"
                                    placeholder="Disability"
                                    value={initialValue.disability}
                                    onChange={changeHandle}
                                />
                            </div>
                        </div>
                        <div className="text-right">
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={upadtePhysicalAttribute}
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default PhysicalAttributes