
function MyShortList() {
  return (
    <>
      <div className="aiz-user-panel overflow-hidden">
        <div className="card">
          <div className="card-header">
            <h5 className="mb-0 h6">My Shortlists</h5>
          </div>
          <div className="card-body">
            <table className="table aiz-table mb-0 footable footable-1 breakpoint-xl" style={{}}>
              <thead>
                <tr className="footable-header">
                  <th className="footable-first-visible" style={{ display: 'table-cell' }}>#</th><th style={{ display: 'table-cell' }}>Image</th><th style={{ display: 'table-cell' }}>Name</th><th data-breakpoints="lg" style={{ display: 'table-cell' }}>age</th><th data-breakpoints="lg" style={{ display: 'table-cell' }}>Religion</th><th data-breakpoints="lg" style={{ display: 'table-cell' }}>location</th><th data-breakpoints="lg" style={{ display: 'table-cell' }}>Mother  Tongue</th><th className="text-right footable-last-visible" data-breakpoints="lg" style={{ display: 'table-cell' }}>Options</th></tr>
              </thead>
              <tbody>
                <tr id="shortlisted_member_37">
                  <td className="footable-first-visible" style={{ display: 'table-cell' }}>1</td><td style={{ display: 'table-cell' }}>
                    <a href="################member-profile/37" className="text-reset c-pointer">
                      <img className="img-md" src="################public/uploads/all/d60ho8ezrvYMxYH7SaU0mF4UmWttV321vSMrZ8yz.png" height="45px" alt="photo" />
                    </a>
                  </td><td style={{ display: 'table-cell' }}>
                    <a href="################member-profile/37" className="text-reset c-pointer">
                      Robert L. Gardner
                    </a>
                  </td><td style={{ display: 'table-cell' }}>30</td><td style={{ display: 'table-cell' }}>
                    Judaismm
                  </td><td style={{ display: 'table-cell' }}>
                    American Samoa
                  </td><td style={{ display: 'table-cell' }}>
                    English
                  </td><td className="text-right footable-last-visible" style={{ display: 'table-cell' }}>
                    <a href="avascript:void(0);" onclick="express_interest(37)" id="interest_a_id_37" className="btn btn-soft-primary btn-icon btn-circle btn-sm" title="Express Interest">
                      <i className="las la-heart" />
                    </a>
                    <a onclick="remove_shortlist(37)" className="btn btn-soft-danger btn-icon btn-circle btn-sm" title="Remove">
                      <i className="las la-trash-alt" />
                    </a>
                  </td></tr><tr id="shortlisted_member_27">
                  <td className="footable-first-visible" style={{ display: 'table-cell' }}>2</td><td style={{ display: 'table-cell' }}>
                    <a href="################member-profile/27" className="text-reset c-pointer">
                      <img className="img-md" src="################public/uploads/all/uVl6pf6oqBpZiJuai4iwU4KCRAGe9plsh5wDrnkN.png" height="45px" alt="photo" />
                    </a>
                  </td><td style={{ display: 'table-cell' }}>
                    <a href="################member-profile/27" className="text-reset c-pointer">
                      Matthew Ryan
                    </a>
                  </td><td style={{ display: 'table-cell' }}>32</td><td style={{ display: 'table-cell' }}>
                    Islam
                  </td><td style={{ display: 'table-cell' }}>
                    American Samoa
                  </td><td style={{ display: 'table-cell' }}>
                    English
                  </td><td className="text-right footable-last-visible" style={{ display: 'table-cell' }}>
                    <a href="avascript:void(0);" className="btn btn-soft-success btn-icon btn-circle btn-sm" title="Interest Expressed">
                      <i className="las la-heart" />
                    </a>
                    <a onclick="remove_shortlist(27)" className="btn btn-soft-danger btn-icon btn-circle btn-sm" title="Remove">
                      <i className="las la-trash-alt" />
                    </a>
                  </td></tr><tr id="shortlisted_member_28">
                  <td className="footable-first-visible" style={{ display: 'table-cell' }}>3</td><td style={{ display: 'table-cell' }}>
                    <a href="################member-profile/28" className="text-reset c-pointer">
                      <img className="img-md" src="################public/uploads/all/9uC6PPkpVpfOu5FuhF2enDj3Com30pgwnLjWZlR1.png" height="45px" alt="photo" />
                    </a>
                  </td><td style={{ display: 'table-cell' }}>
                    <a href="################member-profile/28" className="text-reset c-pointer">
                      Liam Noah
                    </a>
                  </td><td style={{ display: 'table-cell' }}>32</td><td style={{ display: 'table-cell' }}>
                    Hinduism
                  </td><td style={{ display: 'table-cell' }}>
                    Brazil
                  </td><td style={{ display: 'table-cell' }}>
                    English
                  </td><td className="text-right footable-last-visible" style={{ display: 'table-cell' }}>
                    <a href="avascript:void(0);" className="btn btn-soft-success btn-icon btn-circle btn-sm" title="Interest Expressed">
                      <i className="las la-heart" />
                    </a>
                    <a onclick="remove_shortlist(28)" className="btn btn-soft-danger btn-icon btn-circle btn-sm" title="Remove">
                      <i className="las la-trash-alt" />
                    </a>
                  </td></tr><tr id="shortlisted_member_35">
                  <td className="footable-first-visible" style={{ display: 'table-cell' }}>4</td><td style={{ display: 'table-cell' }}>
                    <a href="################member-profile/35" className="text-reset c-pointer">
                      <img className="img-md" src="################public/uploads/all/ApRvIDurTmGrHYCP8HNrm5gQyfFByvt02JOnxDWg.png" height="45px" alt="photo" />
                    </a>
                  </td><td style={{ display: 'table-cell' }}>
                    <a href="################member-profile/35" className="text-reset c-pointer">
                      Mitchell Johnson
                    </a>
                  </td><td style={{ display: 'table-cell' }}>32</td><td style={{ display: 'table-cell' }}>
                    Judaismm
                  </td><td style={{ display: 'table-cell' }}>
                    American Samoa
                  </td><td style={{ display: 'table-cell' }}>
                    English
                  </td><td className="text-right footable-last-visible" style={{ display: 'table-cell' }}>
                    <a href="avascript:void(0);" className="btn btn-soft-success btn-icon btn-circle btn-sm" title="Interest Expressed">
                      <i className="las la-heart" />
                    </a>
                    <a onclick="remove_shortlist(35)" className="btn btn-soft-danger btn-icon btn-circle btn-sm" title="Remove">
                      <i className="las la-trash-alt" />
                    </a>
                  </td></tr></tbody>
            </table>
            <div className="aiz-pagination">
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default MyShortList