import Home from "../../components/home/Home";
import HeaderPage from "../common/header/Index";

function HomePage() {
  return (
    <>
      {/* <HeaderPage/> */}
      <Home />
    </>
  );
}
export default HomePage;
