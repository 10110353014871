import { message, Popconfirm } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer } from "react-toastify";
import { toastSuccessMessage, toastSuccessMessageError } from "../../../common/tostShow/TostShow";
import { Spinner } from "react-bootstrap";
import { carrerAdd, carrerDelete, carrerIDGet, carrerUpdate, getCarrer } from "../../../../api/Api";

function Career() {
  const [show, setShow] = useState(false);


  const [smShow, setSmShow] = useState(false);

  const [loadingSpin, setLoginSpin] = useState(false)


  const [initialValue, setInitialValue] = useState({
    designation: '',
    company: '',
    start: '',
    end: '',
    status: ''
  })

  const [error, setError] = useState({})
  const [selectedId, setSelectedId] = useState(null);

  // console.log(error);

  const designationRef = useRef(null);
  const companyRef = useRef(null);
  const startRef = useRef(null);
  const endRef = useRef(null);
  const statusRef = useRef(null);


  const handleClose = () => setShow(false);
  const handleShow = () => {
    setSelectedId(null); // Reset selected ID when adding new data
    setInitialValue({ designation: '', company: '', start: '', end: '', status: '' });
    setShow(true);
    setShow(true)
  };


  const validation = (formData) => {
    const errorDta = {}
    if (!formData?.designation) {
      errorDta.designation = 'Designation is Required!'
    }

    if (!formData?.company) {
      errorDta.company = 'Company is Required !'
    }
    if (!formData?.start) {
      errorDta.start = 'Start is Required !'
    }
    if (!formData?.end) {
      errorDta.end = 'End is Required !'
    }
    if (!formData?.status) {
      errorDta.status = 'Status is Required !'
    }
    setError(errorDta)
    return errorDta
  }

  const changeHandle = (e) => {
    const clone = { ...initialValue }
    const value = e.target.value
    const name = e.target.name
    clone[name] = value
    setInitialValue(clone)
  }


  const getById = async (id) => {
    try {
      const res = await carrerIDGet(id)
      setInitialValue(res.data);

    } catch (error) {

    }
  }


  const handleEdit = (id) => {
    setSelectedId(id); // Set the selected ID for editing
    setShow(true); // Open the modal
    getById(id); // Fetch data by ID to populate the form
  };

  const submitData = async () => {

    const validateResult = validation(initialValue);

    // If there are errors, focus on the first one
    if (Object.keys(validateResult).length) {
      for (const key in validateResult) {
        if (validateResult.hasOwnProperty(key)) {
          switch (key) {
            case 'designation':
              designationRef.current.focus();
              break;
            case 'company':
              companyRef.current.focus();
              break;
            case 'start':
              startRef.current.focus();
              break;
            case 'end':
              endRef.current.focus();
              break;
            case 'status':
              statusRef.current.focus();
              break;
            default:
              break;
          }
          break;
        }
      }
      return;
    }

    try {
      setLoginSpin(true)
      // const res = await educationAdd(initialValue)
      // console.log(res?.data.error);
      const res = selectedId
        ? await carrerUpdate(selectedId, initialValue)  // Update data
        : await carrerAdd(initialValue);
      if (res?.error == false) {
        toastSuccessMessage(res?.message)
        setLoginSpin(false)
        getCmsReport()
        setShow(false)
      } else {
        toastSuccessMessageError(res?.message)
        setLoginSpin(false)
      }
    } catch (error) {
      setLoginSpin(false)
    }
  }




  // listData

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().substr(0, 10);
  };

  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  const [loading, setLoading] = useState(false);
  const [count] = useState(10);  // count is now a number 10
  const [page, setPage] = useState(0)
  const [totalCount, setTotalCount] = useState(null)
  const [data, setData] = useState(null)
  const [allData, setAllData] = useState(null)
  const [filterInitial, setFilterInitial] = useState({
    customer_mobile: '',
    end_date: getCurrentDate(),
    start_date: getCurrentDate(),
    txn_id: '',
  })

  const handleChange = (e) => {
    const clone = { ...filterInitial }
    const value = e.target.value
    const name = e.target.name
    clone[name] = value
    setFilterInitial(clone)
  }

  console.log(data);


  const getCmsReport = async (input) => {
    // console.log('getCmsReport called with input:', input);
    setLoading(true);
    const params = new URLSearchParams({
      ...filterInitial,
      count: count,  // count is a number 10
      page: input,
    })
    // console.log('API params:', params);
    try {
      const res = await getCarrer();
      console.log('API response:', res?.data);
      // setTotalCount(res?.data?.data?.total);
      setData(res?.data);
    } catch (error) {
      console.error('API error:', error);
    }
    setLoading(false);
  };

  const onChangeVal = (e) => {
    setPage(e - 1)
    getCmsReport(e - 1)
  };


  useEffect(() => {
    getCmsReport(0)
  }, [])



  // listData

  //delete

  const deleteItem = async (id) => {
    try {
      const res = await carrerDelete(id)
      console.log(res);

      if (res?.data?.error == false) {
        toastSuccessMessage(res?.data?.message)
        setTimeout(() => {
          getCmsReport()
        }, 1000)
      } else {
        toastSuccessMessageError(res?.data?.message)
      }

    } catch (error) {

    }
  }

  const confirm = (id) => {
    // console.log(e);
    // message.success('Click on Yes');
    deleteItem(id)
  };
  const cancel = (e) => {
    // console.log(e);
    message.error('ok');
  };

  //delete


  // useEffect(() => {
  //   getById()
  // }, [show])
  return (
    <>


      <div className="card">
        <div className="card-header">
          <h5 className="mb-0 h6">Career/आजीविका</h5>
          <div className="text-right">
            <a
              onClick={handleShow}
              href="javascript:void(0);"
              className="btn btn-sm btn-primary "
            >
              <i className="las mr-1 la-plus" />
              Add New
            </a>
          </div>
        </div>
        <div className="card-body">
          <table
            className="table aiz-table footable footable-1 breakpoint-xl"
            style={{}}
          >
            <thead>
              <tr className="footable-header">
                <th
                  className="footable-first-visible"
                  style={{ display: "table-cell" }}
                >
                  designation/पद का नाम
                </th>
                <th style={{ display: "table-cell" }}>Company/कंपनी</th>
                <th data-breakpoints="md" style={{ display: "table-cell" }}>
                  Start/शुरू
                </th>
                <th data-breakpoints="md" style={{ display: "table-cell" }}>
                  End/अंत
                </th>
                <th data-breakpoints="md" style={{ display: "table-cell" }}>
                  Status/स्थिति
                </th>
                <th
                  className="text-right footable-last-visible"
                  style={{ display: "table-cell" }}
                >
                  Options/विकल्प
                </th>
              </tr>
            </thead>

            <tbody>

              {data && data?.map((item) => {
                console.log(item);

                return <tr>
                  <td
                    className="footable-first-visible"
                    style={{ display: "table-cell" }}
                  >
                    {item?.designation}
                  </td>
                  <td style={{ display: "table-cell" }}>{item?.company}</td>
                  <td style={{ display: "table-cell" }}>{item?.start}</td>
                  <td style={{ display: "table-cell" }}>{item?.end}</td>
                  <td style={{ display: "table-cell" }}>
                    {item?.status == true ? 'Active' : 'DeActive'}
                    {/* <label className="aiz-switch aiz-switch-success mb-0">
                      <input
                        type="checkbox"
                        id="status.0"
                        onchange="update_education_present_status(this)"
                        defaultValue={11}
                        defaultChecked
                        data-switch="success"
                      />
                      <span />
                    </label> */}
                  </td>
                  <td
                    className="text-right footable-last-visible"
                    style={{ display: "table-cell" }}
                  >
                    <a
                      href="javascript:void(0);"
                      className="btn btn-soft-info btn-icon btn-circle btn-sm"
                      onclick="education_edit_modal('11');"
                      title="Edit"
                      onClick={() => handleEdit(item._id)}
                    >
                      <i className="las la-edit" />
                    </a>
                    <Popconfirm
                      title="Delete the task"
                      description="Are you sure to delete?"
                      onConfirm={() => confirm(item?._id)}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div className="btn btn-soft-danger btn-icon btn-circle btn-sm confirm-delete"> <i className="las la-trash" /></div>

                    </Popconfirm>


                  </td>
                </tr>
              })}

            </tbody>
          </table>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <h5 className="modal-title h6">{selectedId ? 'Update' : 'Add'} Career Info</h5>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <label className="col-md-3 col-form-label">
                Designation <span style={{ color: 'red' }}>*</span>
              </label>
              <div className="col-md-9">
                <input
                  type="text"
                  name="designation"

                  className="form-control"
                  placeholder="designation"
                  ref={designationRef}
                  value={initialValue.designation}
                  onChange={changeHandle}
                />

                {error?.designation && <p style={{ color: 'red', margin: '0px' }}>
                  {error?.designation}
                </p>}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-3 col-form-label">
                Company <span style={{ color: 'red' }}>*</span>
              </label>
              <div className="col-md-9">
                <input
                  type="text"
                  name="company"

                  placeholder="Institution"
                  className="form-control"
                  ref={companyRef}
                  value={initialValue.company}
                  onChange={changeHandle}
                />
                {error?.company && <p style={{ color: 'red', margin: '0px' }}>
                  {error?.company}
                </p>}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-3 col-form-label">Start <span style={{ color: 'red' }}>*</span></label>
              <div className="col-md-9">
                <input
                  type="number"
                  name="start"

                  className="form-control"
                  placeholder="Start"
                  ref={startRef}
                  value={initialValue.start}
                  onChange={changeHandle}
                />
                {error?.start && <p style={{ color: 'red', margin: '0px' }}>
                  {error?.start}
                </p>}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-3 col-form-label">End <span style={{ color: 'red' }}>*</span></label>
              <div className="col-md-9">
                <input
                  type="number"
                  name="end"
                  defaultValue={2015}
                  placeholder="End"
                  className="form-control"
                  ref={endRef}
                  value={initialValue.end}
                  onChange={changeHandle}
                />
                {error?.end && <p style={{ color: 'red', margin: '0px' }}>
                  {error?.end}
                </p>}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-3 col-form-label">Status <span style={{ color: 'red' }}>*</span></label>
              <div className="col-md-9">
                <select className="form-select" aria-label="Default select example" name="status" ref={statusRef} value={initialValue?.status} onChange={changeHandle}>
                  <option selected>Open this select menu</option>
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
                {error?.status && <p style={{ color: 'red', margin: '0px' }}>
                  {error?.status}
                </p>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={submitData}>
              {loadingSpin ? <Spinner animation="border" style={{ textAlign: 'center' }} variant="success" /> : `${selectedId ? 'Update' : 'Add'} Career Info`}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* <ToastContainer /> */}


      {/* <div className="card">
        <div className="card-header">
          <h5 className="mb-0 h6">Career</h5>
          <div className="text-right">
            <a
              onClick={handleShow}
              href="javascript:void(0);"
              className="btn btn-sm btn-primary "
            >
              <i className="las mr-1 la-plus" />
              Add New
            </a>
          </div>
        </div>
        <div className="card-body">
          <table
            className="table aiz-table footable footable-2 breakpoint-xl"
            style={{}}
          >
            <thead />
            <tbody>
              <tr className="footable-header">
                <th
                  className="footable-first-visible"
                  style={{ display: "table-cell" }}
                >
                  designation
                </th>
                <th style={{ display: "table-cell" }}>company</th>
                <th data-breakpoints="md" style={{ display: "table-cell" }}>
                  Start
                </th>
                <th data-breakpoints="md" style={{ display: "table-cell" }}>
                  End
                </th>
                <th data-breakpoints="md" style={{ display: "table-cell" }}>
                  Status
                </th>
                <th
                  data-breakpoints="md"
                  className="text-right footable-last-visible"
                  style={{ display: "table-cell" }}
                >
                  Options
                </th>
              </tr>
              <tr>
                <td
                  className="footable-first-visible"
                  style={{ display: "table-cell" }}
                >
                  Mental health social worker
                </td>
                <td style={{ display: "table-cell" }}>Pro Star</td>
                <td style={{ display: "table-cell" }}>2020</td>
                <td style={{ display: "table-cell" }}>2030</td>
                <td style={{ display: "table-cell" }}>
                  <label className="aiz-switch aiz-switch-success mb-0">
                    <input
                      type="checkbox"
                      id="status.0"
                      onchange="update_career_present_status(this)"
                      defaultValue={11}
                      defaultChecked
                      data-switch="success"
                    />
                    <span />
                  </label>
                </td>
                <td
                  className="text-right footable-last-visible"
                  style={{ display: "table-cell" }}
                >
                  <a
                    href="javascript:void(0);"
                    className="btn btn-soft-info btn-icon btn-circle btn-sm"
                    onclick="career_edit_modal('11');"
                    title="Edit"
                    onClick={handleShow}
                  >
                    <i className="las la-edit" />
                  </a>
                  <a
                    href="javascript:void(0);"
                    className="btn btn-soft-danger btn-icon btn-circle btn-sm confirm-delete"
                    data-href="################career/destroy/11"
                    title="Delete"
                    onClick={() => setSmShow(true)}
                  >
                    <i className="las la-trash" />
                  </a>

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <h5 className="modal-title h6">Add Careear Info</h5>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          Designation
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            name="degree"

                            className="form-control"
                            placeholder="Degree"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          company
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            name="institution"

                            placeholder="Institution"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">Start</label>
                        <div className="col-md-9">
                          <input
                            type="number"
                            name="education_start"

                            className="form-control"
                            placeholder="Start"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">End</label>
                        <div className="col-md-9">
                          <input
                            type="number"
                            name="education_end"

                            placeholder="End"
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={handleClose}>
                        Add Career Info
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  <Modal
                    size="sm"
                    show={smShow}
                    onHide={() => setSmShow(false)}
                    aria-labelledby="example-modal-sizes-title-sm"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-modal-sizes-title-sm">
                        <h6>Delete Confirmation</h6>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="mt-1">Are you sure to delete this?</p>
                      <button
                        type="button"
                        className="btn btn-light mt-2"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <a
                        id="delete-link"
                        className="btn btn-primary mt-2"
                        href="################education/destroy/2"
                      >
                        Delete
                      </a>
                    </Modal.Body>
                  </Modal>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
    </>
  );
}
export default Career;
