import Header from "../../../components/common/header/Header";

function HeaderPage() {
  return (
    <>
      <Header />
    </>
  );
}
export default HeaderPage;
