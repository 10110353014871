
import axiosInstancee from "../axiosServiseFactory/aesEncription/AesEncripition";

// import axiosInstance from "../../axiosServiseFactory/AxiosServiseFactory";
import axios from "axios";
import { baseUrl } from "../baseUrl";
import { baseUrlAdmin } from "../baseUrl";

// const token = window.localStorage.getItem("userToken")

export const resiter = (data) => {
    return axios.post(`${baseUrl}user/adduser`, data);
};

export const loginn = (data) => {
    return axiosInstancee.post(`${baseUrl}user/login`, data);
};

export const getEducation = (value, count, search) => {
    // console.log(value, count);

    return axiosInstancee.get(`${baseUrl}education/get?page=${0}`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
};

export const educationAdd = (data) => {
    return axiosInstancee.post(`${baseUrl}education/add`, data, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
};




export const educationDelete = (id) => {
    return axiosInstancee.delete(`${baseUrl}education/delete/${id}`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
};


export const educationIDGet = (id) => {
    // console.log(id);

    return axiosInstancee.get(`${baseUrl}education/get/${id}`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
};


export const educationUpdate = (id, data) => {
    return axiosInstancee.put(`${baseUrl}education/update/${id}`, data, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
};




export const getCarrer = (value, count, search) => {
    // console.log(value, count);

    return axiosInstancee.get(`${baseUrl}career/get?page=${0}`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
};

export const carrerAdd = (data) => {
    return axiosInstancee.post(`${baseUrl}career/add`, data, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
};




export const carrerDelete = (id) => {
    return axios.delete(`${baseUrl}career/delete/${id}`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
};


export const carrerIDGet = (id) => {
    // console.log(id);

    return axiosInstancee.get(`${baseUrl}career/get/${id}`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
};


export const carrerUpdate = (id, data) => {
    return axiosInstancee.put(`${baseUrl}career/update/${id}`, data, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
};


export const residenceCountryApi = () => {
    // console.log(id);

    return axiosInstancee.get(`${baseUrlAdmin}country`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
};
export const martialStatusApi = () => {
    // console.log(id);

    return axiosInstancee.get(`${baseUrlAdmin}marital-status`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
};

export const religionApi = () => {
    // console.log(id);

    return axiosInstancee.get(`${baseUrlAdmin}religion`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
};

export const casteApi = () => {
    // console.log(id);

    return axiosInstancee.get(`${baseUrlAdmin}caste`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
};

export const subCasteApi = () => {
    // console.log(id);

    return axiosInstancee.get(`${baseUrlAdmin}sub-caste`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}
export const languageApi = () => {
    // console.log(id);

    return axiosInstancee.get(`${baseUrlAdmin}language`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}

export const memelanguageApi = () => {
    // console.log(id);

    return axiosInstancee.get(`${baseUrlAdmin}mem-language`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}

export const stateApi = () => {
    // console.log(id);

    return axiosInstancee.get(`${baseUrlAdmin}state`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}

export const familyValueApi = () => {
    // console.log(id);

    return axiosInstancee.get(`${baseUrlAdmin}family-value`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}

export const onBehalfApi = () => {
    // console.log(id);

    return axiosInstancee.get(`${baseUrlAdmin}on-behalf`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}

export const getprofileApi = () => {
    // console.log(id);
    return axiosInstancee.get(`${baseUrl}expectation/get`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}
export const cityApi = () => {
    // console.log(id);
    return axiosInstancee.get(`${baseUrlAdmin}city`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}

export const UpdatePartnerExpactetionApi = (value) => {
    // console.log(id);
    return axiosInstancee.put(`${baseUrl}expectation/update`, value, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}



export const physicalAttributeGetApi = () => {
    // console.log(id);
    return axiosInstancee.get(`${baseUrl}physical-attribute/get`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}

export const UpdatePhysicalAttributeApi = (value) => {
    // console.log(id);
    return axiosInstancee.put(`${baseUrl}physical-attribute/update`, value, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}

export const UpdatePhysicalInforApi = (value) => {
    // console.log(id);
    return axiosInstancee.put(`${baseUrl}physical-info/update`, value, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}

export const getPhysicalInforApi = (value) => {
    // console.log(id);
    return axiosInstancee.get(`${baseUrl}physical-info/get`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}


export const basicInfoGetApi = () => {
    // console.log(id);
    return axiosInstancee.get(`${baseUrl}basic-info/get`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}

export const UpdateBasicInfoGetApi = (value) => {
    // console.log(id);
    return axiosInstancee.put(`${baseUrl}basic-info/update`, value, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}


export const presnolInfoGetApi = () => {
    // console.log(id);
    return axiosInstancee.get(`${baseUrl}personal-info/get`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}

export const UpdatePresnolInfoApi = (value) => {
    // console.log(id);
    return axiosInstancee.put(`${baseUrl}personal-info/update`, value, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}


export const cloudImage = (value) => {
    return axios.post(`https://onlineparttimejobs.in/api/cloudinaryImage/addImage`, value);
};


export const introPageGetApi = () => {
    // console.log(id);
    return axiosInstancee.get(`${baseUrl}user/profile/view`, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}

export const UpdateIntroPageApi = (value) => {
    // console.log(id);
    return axiosInstancee.put(`${baseUrl}user/profile/update`, value, {
        headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
    });
}





