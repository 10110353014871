import MyWallet from "../../../components/dashboard/myWallet/MyWallet";


function MyWalletPage() {
  return (
    <>
      <MyWallet />
    </>
  );
}
export default MyWalletPage;
