import ReferalEarning from "../../../../components/dashboard/referalSystem/referalEarning/ReferaEarning"


function ReferalEarningPage () {
    return (
        <>
        <ReferalEarning/>
        </>
    )
}
export default ReferalEarningPage